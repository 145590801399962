.RedcyclePreviewCard {
  img {
    margin-left: auto;
    margin-right: auto;
    top: 0;
    height: 100%;
    width: 100%;
  }
  height: 100%;
}
