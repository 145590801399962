@import "../../../index";

.AllDependencies {
  display: flex;
  flex-flow: column;
  height: 100%;
  .MuiTypography-root {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .MuiSvgIcon-root {
      margin-right: 5px;
      color: #f6eed5;
    }
  }
  #all-dependencies-list {
    position: relative;
    height: 100%;
    min-height: 500px;
    .MuiList-root {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0;
      background-color: white;
      color: black;
      overflow: auto;
      border-radius: 4px;
      li {
        background-color: white;
        font-size: 20px;
      }
      ul {
        padding: 0;
      }
      .MuiListSubheader-root {
        background-color: $color-primary;
        color: white;
        height: 64px;
        line-height: 64px;
      }
    }
  }
}
