@import "../../index";

.Footer {
  padding-left: 5px;
  padding-right: 5px;
  .twitter-tile {
    .MuiPaper-root {
      background-color: #1da1f2;
    }
  }
  .flickr-tile {
    .MuiPaper-root {
      background-color: white;
    }
  }
  .github-tile {
    .MuiPaper-root {
      background-color: black;
    }
    img {
      background-color: white;
    }
  }
  .email-tile {
    .MuiPaper-root {
      background-color: $color-primary;
    }
  }
  .dependencies-tile {
    .MuiSvgIcon-root {
      color: #00ff00;
    }
  }
  .guldentech-tile {
    .MuiPaper-root {
      background-color: #eac956;
    }
  }
}

#dependency-credits-container {
  outline: none;
  #modal-paper {
    background-color: #424242;
    padding: 10px;
    #full-screen-exit-button {
      position: absolute;
      top: 5px;
      right: 5px;
      color: white;
    }
  }
}
