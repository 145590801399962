@import "../../index";

.DependencyCredits {
  background-color: #424242;
  color: white;
  #introduction {
    #heading {
      text-align: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      .MuiSvgIcon-root {
        margin-right: 5px;
        color: #00ff00;
      }
    }
    #body {
      margin-top: 10px;
    }
  }
  .MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.12);
  }
  #primary-grid-container {
    height: 100%;
    padding: 15px;
    .heading {
      text-align: center;
    }
  }
}
