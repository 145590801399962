@import "../../index";

.MME {
  padding-left: 10px;
  padding-right: 10px;

  .mach-e-forum-orders {
    margin-top: 50px;
    .mach-e-forum-orders-header {
      span {
        height: 100px;
      }
    }
    h2 {
      display: inline;
    }
  }
}
