@import "../../index";

.Navigator {
  #nav-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 75px;
    height: 75px;
    z-index: 3;
  }
  #nav-icon-link:focus {
    #nav-icon {
      filter: brightness(75%);
    }
  }
  #toolbar {
    position: absolute;
    left: 25px;
    top: 5px;
    margin: 5px;
    background-color: #{$color-primary};
    border-radius: 8px;
    padding-left: 75px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
  }
  .button {
    border-radius: 5px;
    background-color: white;
    color: black;
    font-weight: bold;
  }
  .left-button {
    margin-right: 12px;
  }
  .middle-button {
    margin-left: 12px;
    margin-right: 12px;
  }
  .right-button {
    margin-left: 12px;
  }
  .Link {
    text-decoration: none;
  }
}

#projects-menu {
  .Link {
    text-decoration: none;
    color: black;
  }
}
