@import "../../index";

.PreviewCard {
  width: 100%;
  height: 100%;

  .MuiPaper-rounded {
    background-color: #424242;
  }

  .MuiCard-root {
    display: block;
    height: 100%;

    .MuiTypography-root {
      color: white;
    }

    .MuiCardMedia-root {
      height: 0;
      padding-top: 66.66%;
      overflow: hidden;
      position: relative;

      .children-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .MuiBackdrop-root {
        position: absolute;
        z-index: 1;

        .backdrop-placeholder {
          background-color: #c4ccc4;
          height: 100%;
          width: 100%;

          .loading-indicator-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
          }

          .MuiCircularProgress-colorPrimary {
            color: $color-primary;
          }
        }
      }
    }

    .MuiCardContent-root {
      height: 150px;
    }

    .MuiCardActions-root {
      .link {
        text-decoration: none;
      }

      .MuiButtonBase-root {
        background-color: $color-primary;
        color: white;
        font-weight: bold;
      }
    }
  }
}
