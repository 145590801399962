@import "../../index";

.MyTable {
  #MyTable-title {
    margin: 1em;
  }
  #MyTable-csv-export {
    color: black;
    float: right;

    .MuiIconButton-root {
      margin: 0.5em;
    }
  }
  #MyTable-loading-indicator-row {
    .MuiTableCell-root {
      padding: 0;
    }

    .MuiLinearProgress-root {
      color: $color-primary;
    }
  }
}
