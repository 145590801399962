.App {
  position: relative;
  min-height: 100vh;

  .top-padding {
    padding-top: 100px;
  }

  .content-wrapper {
    width: 100%;
    padding-bottom: 500px;
  }

  #footer-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 300px;
  }
}
