@import "../../../index";

.Backdrop {
  width: 100%;
  height: 100%;
  #backdrop-container {
    position: relative;
    height: 350px;
    object-fit: cover;
    overflow: hidden;

    #backdrop {
      height: 350px;
      left: 0;
      top: 0;
      width: 100%;
      position: relative;

      .MuiBackdrop-root {
        position: absolute;
        z-index: 1;

        #image-placeholder {
          background-color: #c4ccc4;
          height: 100%;
          width: 100%;
        }
      }

      img {
        position: absolute;
        margin: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        object-fit: fill;
        display: block;
        filter: brightness(75%);
        z-index: 0;
      }
    }

    #greeting-container {
      position: absolute;
      bottom: 50px;
      width: 100%;
      z-index: 2;
    }

    #greeting {
      text-align: center;
      color: white;
      font-size: 50px;
    }

    #full-screen-button {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
    }
  }
}

#backdrop-photo-viewer-modal {
  #photo-viewer-container {
    outline: none;

    #full-screen-exit-button {
      position: absolute;
      top: 5px;
      right: 5px;
      color: black;
    }

    #flickr-link-button {
      text-align: center;
      display: block;
      margin: 10px auto;
      width: 15em;
      font-weight: bold;
      background-color: $color-primary;
      color: white;

      :link,
      :visited,
      :hover,
      :active {
        color: white;
        text-decoration: none;
      }
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 90vw;
      max-height: 85vh;
    }
  }
}
