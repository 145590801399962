.FooterCard {
  width: 100%;
  .filter {
    filter: brightness(50%) blur(0.5rem);
  }
  .MuiPaper-root:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .MuiPaper-root {
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    background-color: #424242;
    .footer-card-title-container {
      text-align: center;
      background-color: white;
      color: black;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .MuiTypography-root {
        font-size: 20px;
      }
    }
    .footer-card-children {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      svg,
      img {
        height: 50%;
        width: 50%;
      }
    }
    .MuiCardActions-root {
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .footer-card-open-button {
        width: 100%;
        text-align: right;
      }
      .footer-card-launch-button {
        width: 100%;
        text-align: center;
      }
      .footer-card-copy-button {
        width: 100%;
        text-align: left;
      }
      .MuiIconButton-root {
        position: relative;
        height: 15px;
        width: 15px;
        padding: 15px;
        .MuiIconButton-label {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
